import React from 'react';
import clsx from  'clsx';
import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import Footnotes from '../components/Footnotes/Footnotes';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import References from '../components/References/References';
import EnlargeIcon from '../images/enlargeIcon.inline.svg';
import SafetyFirstAdverseReactionsTableMobile from '../images/safety-first/safetyFirstAdverseReactionsTableMobile.inline.svg';
import SafetyFirstAdverseReactionsTable from '../images/safety-first/safetyFirstAdverseReactionsTable.inline.svg';
import SafetyFirstAbnormalitiesTableMobile from '../images/safety-first/safetyFirstAbnormalitiesTableMobile.inline.svg';
import SafetyFirstAbnormalitiesTable from '../images/safety-first/safetyFirstAbnormalitiesTable.inline.svg';
import JumpLink from '../components/JumpLink/JumpLink';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';

import { GtagHead } from '../components/GTAG';
export function Head() {
  return (
    <>
      <GtagHead/>
      <title>Safety | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description'
        content='Learn more about the LOQTORZI® safety demonstrated in the JUPITER-02 trial.'/>
    </>
  )
}

export default function SafetyFirst() {
  const safety = {
    header: <h2>JUPITER-02 SAFETY</h2>,
    title: <h3>LOQTORZI<sup>®</sup> adverse reactions<sup>1</sup></h3>,
    paragraph1: <p className='font-MontserratMedium tablet:text-left'>Adverse reactions (≥10%) in patients with R/M NPC who received LOQTORZI<sup>®</sup> + chemotherapy in JUPITER-02</p>,
  };

  const safetyAbnormalies = {
    title: <h3>JUPITER-02 laboratory abnormalities</h3>,
    paragraph1: <p className='font-MontserratMedium tablet:text-left'>Select laboratory abnormalities (≥20%) that worsened from baseline in patients with R/M NPC who received LOQTORZI<sup>®</sup> in combination with cisplatin and gemcitabine in JUPITER-02</p>,
  };

  const pageReferences = [
    <span>LOQTORZI<sup>®</sup> (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc.</span>
  ];

  const ctaDescriptionListData = [
    {
      cta: {
        text: <>LOQTORZI<sup>®</sup> at a glance</>,
        url:'/loqtorzi-at-a-glance/'
      },
      text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
        to help you <br className='desktop:hidden' />support your patients.</div>
    }, {
      cta: {
        text: <span>Interactive <br/>
          Prescribing Information </span>,
        url:'/loqtorzi-prescribing-information/'
      },
      text: <div>Explore LOQTORZI<sup>®</sup> data
        <br className='desktop:hidden' /> in the interactive
        <br className='desktop:hidden' /> Prescribing Information.</div>
    },
    {
      cta: {
        text: 'Get Updates',
        url: '/get-updates/'
      },
      text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
    }
  ];

  return (
    <Layout>
      <Title pageName={'Safety'}>
        Safety&mdash;<br className='tablet:hidden' />
        first-line<br />
        treatment
      </Title>

      <DynamicContent globalWrapperClassName={'pb-[3.3rem]'} header={safety.header}>
        <div className='wrapperContent !pb-0'>
          <div className='title mt-[2rem]'>{safety.title}</div>
          <div className='paragraph !text-center tablet:!text-center'>{safety.paragraph1}</div>
          <div className='flex items-center justify-end gap-[1rem] font-MontserratMedium text-[1rem] text-gray-250 tablet:hidden'>
            <span>PINCH TO ENLARGE</span>
            <EnlargeIcon></EnlargeIcon>
          </div>
          <SafetyFirstAdverseReactionsTableMobile className={clsx('tablet:hidden w-full h-auto m-auto mt-[.5rem]')} />
          <SafetyFirstAdverseReactionsTable className={clsx('hidden w-full h-auto m-auto mb-[3rem] tablet:block')} />

        <Footnotes className={'mt-[2rem]'} items={[{
            bullet: '*',
            text: <span>National Cancer Institute Common Terminology Criteria for Adverse Events v5.0.<sup>1</sup></span>
          },
          {
            bullet: <sup>†</sup>,
            text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
          },
          {
            bullet: <sup>‡</sup>,
            text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
          },
          {
            bullet: <sup>§</sup>,
            text: <span>Includes mouth ulceration, stomatitis, and radiation stomatitis.<sup>1</sup></span>
          },
          {
            bullet: <sup>||</sup>,
            text: <span>Includes hypothyroidism, triiodothyronine decreased, triiodothyronine free decreased, and thyroiditis.<sup>1</sup></span>
          },
          {
            bullet: <sup>¶</sup>,
            text: <span>Includes acneiform dermatitis, allergic dermatitis, catheter-site rash, dermatitis, drug eruption, eczema, erythema, macule, maculopapular rash, palmar-plantar erythrodysesthesia syndrome, papule, pruritic rash, rash, and urticaria.<sup>1</sup></span>
          },
          {
            bullet: <span>#</span>,
            text: <span>Includes asthenia and fatigue.<sup>1</sup></span>
          },
          {
            bullet: '**',
            text: <span>Includes hypoesthesia, neuralgia, neuropathy peripheral, paresthesia, and peripheral sensory neuropathy.<sup>1</sup></span>
          },
          {
            bullet: <sup>††</sup>,
            text: <span>Includes cough and productive cough.<sup>1</sup></span>
          },
          {
            bullet: <sup>‡‡</sup>,
            text: <span>Includes back pain, bone pain, musculoskeletal chest pain, musculoskeletal pain, myalgia, neck pain, pain in extremity, pain in jaw.<sup>1</sup></span>
          },
          {
            bullet: <sup>§§</sup>,
            text: <span>Includes acute sinusitis, bronchitis, laryngitis, nasopharyngitis, pharyngitis, respiratory tract infection, rhinitis, sinusitis, and upper respiratory tract infection.<sup>1</sup></span>
          },
          {
            bullet: <sup>||||</sup>,
            text: <span>Includes aspiration pneumonia and pneumonia.<sup>1</sup></span>
          },
          {
            bullet: <sup>¶¶</sup>,
            text: <span>Includes blood pressure increased, blood pressure systolic increased, hypertension, and hypertensive crisis.<sup>1</sup></span>
          }
        ]} />
          <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>
            Serious and fatal adverse reactions
          </div>
          <ul className='bullet-list mt-[1rem]'>
            <li>
              Serious adverse reactions occurred in 43% of patients receiving LOQTORZI<sup>®</sup> in combination with cisplatin and gemcitabine
              <ul className='bullet-list-dash leading-[1.3]'>
                <li>Serious adverse reactions in ≥2% were thrombocytopenia (14%), neutrophil count decreased (10%), pneumonia (10%), anemia (9%), abnormal hepatic function (2.7%), and rash (2.1%)</li>
              </ul>
            </li>
            <li>Of the patients who received LOQTORZI<sup>®</sup> in combination with cisplatin and gemcitabine, there were 3 fatal adverse reactions (2.1%): 1 due to epistaxis; 1 due to intracranial hemorrhage associated with immune-related thrombocytopenia and coagulopathy, and 1 due to pneumonia </li>
          </ul>
          <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>
            Discontinuations and dosage interruptions
          </div>
          <ul className='bullet-list mt-[1rem]'>
            <li>Permanent discontinuation of LOQTORZI<sup>®</sup>, when given in combination with cisplatin and gemcitabine, due to an adverse reaction occurred in 12% of patients
              <ul className='bullet-list-dash leading-[1.3]'>
                <li>Adverse reactions resulting in permanent discontinuation of LOQTORZI<sup>®</sup> in ≥1% of patients were pneumonia (2.1%), pulmonary tuberculosis (1.4%), rash (1.4%), and vomiting (1.4%)</li>
              </ul>
            </li>
          </ul>
          <ul className='bullet-list mt-[1rem]'>
            <li>
            Dosage interruptions of LOQTORZI<sup>®</sup> due to an adverse reaction occurred in 50% of patients
              <ul className='bullet-list-dash leading-[1.3]'>
                <li>Adverse reactions which required dosage interruption in ≥2% were anemia (17%), decreased neutrophils (12%), thrombocytopenia (12%), acute kidney injury (4.1%), pneumonia (6%), fatigue (2.7%), upper respiratory infection (2.7%), and hypothyroidism (2.1%)</li>
              </ul>
            </li>
          </ul>
          <div className='title mt-[4rem]'>{safetyAbnormalies.title}</div>
          <div className='paragraph !text-center tablet:!text-center'>{safetyAbnormalies.paragraph1}</div>
          <div className='flex items-center justify-end gap-[1rem] font-MontserratMedium text-[1rem] text-gray-250 tablet:hidden'>
            <span>PINCH TO ENLARGE</span>
            <EnlargeIcon></EnlargeIcon>
          </div>
          <SafetyFirstAbnormalitiesTableMobile className={clsx('tablet:hidden w-full h-auto m-auto mt-[.5rem]')} />
          <SafetyFirstAbnormalitiesTable className={clsx('hidden w-full h-auto m-auto mb-[0rem] tablet:block')} />
        </div>
      </DynamicContent>

      <GlobalWrapper showBorderLeft={true} className='pl-[0.8rem] pr-[1.5rem] tablet:pl-[3rem]'>
        <div className={'pt-[2.4rem] pl-[.8rem] tablet:pl-0'}>
        <Footnotes items={[{
            bullet: <sup>##</sup>,
            text: <span>Each test incidence is based on the number of patients who had both baseline and at least one on-study laboratory measurement available: LOQTORZI<sup>®</sup>/chemotherapy (range: 139 to 146 patients) and placebo/chemotherapy (range: 136 to 143 patients).<sup>1</sup></span>
          },
          {
            bullet: '***',
            text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
          },
          {
            bullet: <sup>†††</sup>,
            text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
          },
          {
            bullet: <sup>‡‡‡</sup>,
            text: <span>Graded per National Cancer Institute Common Terminology Criteria for Adverse Events v5.0.<sup>1</sup></span>
          },
          {
            bullet: '',
            text: <span>R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
          }
        ]} />
        </div>
      </GlobalWrapper>
      <JumpLink content={{title:'See JUPITER-02 efficacy', link:'/loqtorzi-efficacy-first-line-treatment/', class:'special-bottom'}}/>
      <GlobalWrapper showBorderLeft={true} className={'pt-[2.7rem] pl-[0.8rem] pr-[1.5rem] tablet:pl-[3rem]'}>
        <References className={ '!ml-0 !p-0 !pb-[1.4rem]' } items={ pageReferences }></References>
      </GlobalWrapper>
      <GlobalWrapper className='pl-[0.8rem] pr-[1.5rem] border-left tablet:pl-[3rem]'>
        <div className='pb-[1.4rem]'>&nbsp;</div>
      </GlobalWrapper>
      <CtaDescList content={ctaDescriptionListData} />
    </Layout>
  );
}