import React, { Fragment } from 'react';
import * as styles from './References.module.css';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';
import clsx from 'clsx';

export default function References({ className, items, border=false }) {

  let list = items.map((item, index) =>
    item !== '' &&
    <Fragment key={index}>
      <span className={ styles.title }> { index + 1 }.&nbsp;</span><span>{item}</span>
    </Fragment>
  );

  return (
    <div>
      <GlobalWrapper className={clsx(styles.references, className, border && 'border-left')}>
        <span className={styles.title}>{items.length > 1 ? 'References:' : 'Reference:' }</span>
        { list }
      </GlobalWrapper>
    </div>
  );
}