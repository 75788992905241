
import clsx from 'clsx';
import React from 'react';
import * as styles from './JumpLink.module.css';
import { Link } from 'gatsby';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';
import jumpLinkArt from '../../images/jumpLink.png';

export default function JumpLink({ className, wrapperClassName, content, border=true}) {
  return (
    <section className={ clsx(className) } >
      <GlobalWrapper className={ wrapperClassName } showBorderLeft={ border }>
        <section className={ styles.wrapper }>
          <div className={(content.class===false)?styles.content.class:styles.specialTop}>
            <Link to={content.link} className='no-underline'>
              <span>
                <h3>{content.title}
                  <img src={jumpLinkArt} alt='jumpLink' className='inline ml-3' width={25}/>  
                </h3>
              </span>
            </Link>
          </div>
        </section>
      </GlobalWrapper>
    </section>
  );
}