import React from 'react';
import * as styles from './CtaDescList.module.css';
import clsx from 'clsx';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';
import { Link } from 'gatsby';

export default function CtaDescList({ className, content }) {
  return (
    <div className={ clsx(styles.ctaDescList, className) }>
      <GlobalWrapper showBorderLeft={ true } darkmode={ true }>
        {
          content && content.map((item, index) => {
            return(
              <div key={ index } className={ styles.ctaDesc }>
                <div className={ styles.ctaContainer }>
                  {
                    item.cta.type === 'pdf' ?
                      <a className={ styles.cta } href={ item.cta.url } title={ item.cta.title } target={item.cta.target || '_self'}>{ item.cta.text }</a>
                    :
                      <Link className={ styles.cta } to={ item.cta.url } title={ item.cta.title }>{ item.cta.text }</Link>
                  }
                </div>
                <div className={styles.divider}></div>
                <div className={ styles.content }>
                  {
                    item.text
                  }
                </div>
              </div>
            );
          })
        }
      </GlobalWrapper>
    </div>
  );
}