import React from 'react';
import * as styles from '../DynamicContent/DynamicContent.module.css';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';
import clsx from 'clsx';

export default function DynamicContent({
  className,
  globalWrapperClassName,
  headerClassName,
  header = '',
  children,
  divider = true,
  border = true,
  showBorderLeftMobile = false
}) {
  return (
    <div className={ clsx(styles.dContent, className) }>
      <GlobalWrapper className={ clsx(globalWrapperClassName) } showBorderLeft={ border }>
        {header && (
          <div className={ clsx(styles.headerContainer, showBorderLeftMobile && '!ml-0') }>
            <div className={ clsx(styles.header, headerClassName) }> { header } </div>
          </div>
        )}
        { divider && <hr className={ styles.divider }></hr> }
        { children }
      </GlobalWrapper>
    </div>
  );
}
